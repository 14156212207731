<template>
    <div style="padding-bottom: 50px;">

        <div class="verify" v-if="showVerify">
            <div class="logo">
                <img :src="logo_url" class="logo-img">
            </div>
            <img :src="logo_url2" style="width: 106px;">
<!--            <div class="verify-text">此应用需要您的微信授权，才可以正常使用，请先去授权</div>-->
<!--            <div class="verify-text">注意：一定要授权真实微信昵称和头像</div>-->
            <van-button class="verify-button" @click="verify">微信登录</van-button>
            <div class="verify-text" style="margin-top: 20px;line-height: 20px;color: rgba(16, 16, 16, 100);font-size: 14px;font-family: PingFangSC-regular;">
                <van-icon name="warning-o" color="#FEC919" size="20" style="margin-right: 5px;"/>
                一定要授权真实微信昵称和头像
            </div>
        </div>
<!--        <van-button @click="clearStorage">清理缓存</van-button>-->

        <van-dialog v-model="confirmDialog" title="请选择以下哪个是您的微信" @confirm="submit"
                    :before-close="beforeClose"
                    confirm-button-text="提交" confirm-button-color="#F2CB26">
            <div style="margin-top: 20px;max-height: 300px;overflow: auto;">
                <van-cell-group>
                    <van-radio-group v-model="wcId">
                        <van-cell v-for="(item,index) in chatUsers" :key="index">
                            <van-radio :name="item.aliasName">
                                <div style="display: flex;">
                                    <img :src="item.smallHead" style="width:40px;height:40px;border-radius: 50%;margin: 0 10px">
                                    <span style="line-height: 40px">{{item.nickName}}</span>
                                </div>
                                <div style="margin: 5px 0 0 10px;word-break:break-all;">微信号：{{item.aliasName}}</div>
                            </van-radio>
                        </van-cell>
                        <van-cell>
                            <van-radio name="noUser">
                                <div style="margin-left: 10px;height: 40px;line-height: 40px;">以上都不是我</div>
                            </van-radio>
                        </van-cell>
                    </van-radio-group>
                </van-cell-group>
            </div>
        </van-dialog>
    </div>
</template>

<script>
import {Toast, Dialog} from 'vant';
import {mapState} from 'vuex';

export default {
    name: "verify",
    computed: {
        ...mapState(['common'])
    },
    data() {
        return {
            logo_url: require('../../assets/img/qianliang/logo2.png'),
            logo_url2:require('../../assets/img/qianliang/logo4.png'),

            userInfo:{},

            showVerify: false,  // 是否显示授权部分
            confirmDialog:false,  // 确认绑定弹框
            wcId: '',
            chatUsers:[], // 选择微信
            showNone:false,  // 显示无活动（临时变量，下次改版改为全部展示）
        }
    },
    mounted() {
        this.confirmDialog = false
        this.showVerify = false
        // 验证授权状态
        this.verifyLogin()
    },
    methods: {
        // 检查用户授权和绑定状态
        async verifyLogin(){
            let that = this
            // 根据token，检查是否已弹窗授权
            if(!localStorage.getItem('xToken') || localStorage.getItem('completeUrl2')){
                this.showVerify = true
                return
            }

            // 获取用户基本信息
            let userInfo = localStorage.getItem('userInfo')
            if(!userInfo){
                userInfo = await this.getUserInfo()
            } else {
                userInfo = JSON.parse(userInfo)
                that.userInfo = userInfo
            }

            // 是否绑定了账号
            if(userInfo.bindStatus === '0'){
                this.showVerify = true
                await this.toAuth()
                return
            }
            // 已完成静默授权、弹窗授权、已绑定账户
            that.$router.replace('/dashboard')
        },

        // 查询用户信息
        getUserInfo(){
            return new Promise((resolve, reject) => {
                this.post('/member/memberapi/queryInfo').then(result=>{
                    // console.log(result)
                    if(result.respCode === '0'){
                        localStorage.setItem('userInfo',JSON.stringify(result.data))
                        resolve(result.data)
                    } else {
                        this.$dialog({message: '授权失败，请刷新页面重试:<br/>' + result.respMsg});
                        // alert(JSON.stringify(result))
                        resolve(false)
                    }
                }).catch(e=>{
                    resolve(false)
                })
            })
        },
        verify(){
            let completeUrl2 = localStorage.getItem('completeUrl2')
            // 弹窗授权
            if(completeUrl2){
                localStorage.removeItem('completeUrl2')
                location.replace(completeUrl2)
                return
            } else {
                // 跳转到首页
                let url = window.location.href
                location.replace(url.substring(0,url.lastIndexOf('/')) + '/')
                // this.$dialog.alert({
                //     title: '提示',
                //     message: "授权失败，请尝试关闭后重新打开页面",
                // })
            }
        },
        async toAuth(){
            this.post('/member/queryChatUser').then(result => {
                if (result.respCode === "0") {
                    if(result.data.result.length){
                        this.chatUsers = result.data.result
                        this.wcId = ''
                        this.confirmDialog = true
                    } else {
                        this.$router.push('/qr_code')
                    }
                } else {
                    // 提示查询失败
                    this.$dialog.alert({
                        title: '提示',
                        message:result.respMsg,
                    })
                }
            }).catch(e=>{
                console.log(e)
                // 提示查询失败
                this.$dialog.alert({
                    title: '提示',
                    message: "授权信息查询失败",
                })
            })
        },
        submit(){
            if(!this.wcId){
                return
            }
            // 以上都不是：提示绑定加群
            if(this.wcId === 'noUser'){
                this.wcId = ''
                this.$router.push('/qr_code')
            } else {
                this.post('/member/bindWxId',{wcId:this.wcId}).then(result=>{
                    if(result.respCode === "0") {
                        console.log(result.data)
                        localStorage.setItem('userInfo',JSON.stringify(result.data))
                        this.userInfo = result.data
                        this.$dialog.alert({
                            title: '提示',
                            message: '绑定成功',
                        }).then(() => {
                            // 如果没有推荐人，跳转到加群的页面
                            if(!result.data.referenceWcId){
                                this.$router.push('/qr_code')
                                return
                            }
                            this.$router.replace('/dashboard')
                        });
                    } else {
                        this.$router.push('/qr_code')
                    }
                })
            }
        },
        beforeClose(action,done){
            if(!this.wcId){
                Toast('请选择一个选项')
                done(false)
            } else {
                done()
            }
        },
        clearStorage(){
            // 授权相关信息
            localStorage.removeItem('userInfo');
            localStorage.removeItem('xToken')
            localStorage.removeItem('completeUrl2')
            // 打卡相关信息
            localStorage.removeItem('fkClickActivityId')
            localStorage.removeItem('fkClickActivitySpotId')
            localStorage.removeItem('accessUrl')
            localStorage.removeItem('signInTime')
            this.$dialog.alert({
                title: '提示',
                message: "缓存清理成功，请手动关闭并重新打卡当前页面",
            })
        },
    },
}
</script>

<style scoped>
.verify{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.logo{
    /*background: #FEC919;*/
    height: 100px;
    width: 100px;
    /*border-radius: 10px;*/
    position: relative;
    margin: 100px 0 10px;
}
.logo-img{
    width: 100%;
}
.verify-text{
    color: rgba(16, 16, 16, 1);
    font-size: 13px;
    font-family: PingFangSC-regular;
    margin-bottom: 10px;
}
.verify-button{
    margin-top: 30vh;
    background-color: #FEC919;
    color: #000;
    width: 200px;
    height: 45px;
    border-radius: 10px;
    background-color: rgba(254, 201, 25, 100);
    color: rgba(0, 0, 0, 1);
    font-size: 16px;
    text-align: center;
    font-family: Microsoft Yahei;
}
</style>
